.footer {
    background-color: #343A40;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: auto;
    padding: 1rem;
}

.logogabi {
    height: 3.75rem;
    margin: 0.5rem;
}

.footerIcon {
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 0.5rem;
}

.iconFooter {
    margin-right: 0.625rem;
}

.footerLinks {
    color: white;
    text-decoration: none;
}

.footerLinks:hover {
    color: rgb(218, 218, 218);
}

.footer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    margin: 1rem 0;
}

.footer2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 1rem 0;
}

/* Media queries para pantallas más pequeñas */
@media screen and (max-width: 48rem) {
    .footer {
        flex-direction: column;
        height: auto;
    }

    .footer1,
    .footer2 {
        width: 100%;
        justify-content: center;
        gap: 10px;
    }

    .footerIcon {
        font-size: 1rem;
    }

    .logogabi {
        height: 2.5rem;
    }
}
