.cta-container {
    text-align: center;
    background-color: #F8EFEA; /* Suave fondo para armonizar con el esquema de colores */
    padding: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
    margin: 2.5rem auto; /* Ajusta el margen para que sea centrado automáticamente */
    max-width: 90%; /* Limita el ancho máximo para pantallas grandes */
    width: 100%; /* Asegura que ocupe el 100% del ancho disponible */
}

.cta-title {
    color: #E2A896; /* Color de título acorde con el esquema */
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.cta-description {
    color: #6D4C41; /* Color de texto complementario */
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    line-height: 1.5;
}

.cta-button {
    background-color: #E2A896; /* Botón en color acorde al esquema */
    color: #FFFFFF;
    font-size: 1.5rem;
    padding: 0.875rem 2.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center; /* Centra el contenido del botón */
}

.cta-button:hover {
    background-color: #c08f80; /* Sutil cambio de color en hover para mantener la armonía */
    transform: scale(1.05);
}

.cta-button-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
}

/* Media queries para mejorar la responsividad */
@media (max-width: 768px) {
    .cta-title {
        font-size: 2rem;
    }

    .cta-description {
        font-size: 1.25rem;
    }

    .cta-button {
        font-size: 1.25rem;
        padding: 0.75rem 2rem;
    }

    .cta-button-icon {
        font-size: 1.25rem;
    }
}

@media (max-width: 480px) {
    .cta-title {
        font-size: 1.5rem;
    }

    .cta-description {
        font-size: 1rem;
    }

    .cta-button {
        font-size: 1rem;
        padding: 0.5rem 1.5rem;
    }

    .cta-button-icon {
        font-size: 1rem;
    }
}
