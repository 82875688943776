.us {
    display: flex;
    margin-bottom: 3rem;
}

.shadowPhoto {
    width: 26.5625rem;
    margin-top: -3.75rem;
    z-index: -1;
    height: 1.25rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 5.625rem 0.625rem 1.25rem rgba(0, 0, 0, 0.6);
}

.employeePhoto {
    border: 0.625rem solid #E2A896;
    width: 28.125rem;
    margin-top: 3.875rem;
    border-radius: 100%;
}

.employeePhoto:hover {
    margin-top: 1.25rem;
    transition: 1s;
}

.abtUsContainer {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F8EFEA;
    animation: showAbt 1s;
}

@keyframes showAbt {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.separatorDiv1 {
    padding-top: 5rem;
    padding-bottom: 4rem;
    background: linear-gradient(180deg, rgba(248,239,234,1) 30%, #E2A896 100%);
}

.txtAbtUs {
    margin: 4.25rem 0 0 3.125rem;
    width:100%;
    text-align: justify;
    font-size: 1.25rem;
}

.employeeContainer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.employee {
    margin-left: 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.name {
    font-size: 1.3rem;
}

.servicesContainer {
    background-image: url('../../Assets/background.webp');
    background-position: center;
    background-size: 150%;
    background-repeat: no-repeat;
}

.services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0rem 4rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
}

.abordaje_integral {
    position: absolute;
    width: 12.5rem;
    top: 19rem;
}

.educacion_alimentaria {
    position: absolute;
    width: 14rem;
    top: 21rem;
}

.planes_personalizados {
    position: absolute;
    width: 14rem;
    top: 18rem;
}

.titleService {
    /* width: 21.875rem; */
    line-height: 0.5;
    position: absolute;
    left: 0;
    right: 0;
    top: 1.5rem;
}

.subtitleService {
    position: absolute;
    left: 0;
    right: 0;
    top: 3rem;
    padding-top: 0;
    padding-bottom: 1rem;
}

.titleServices {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E2A896;
    height: 4rem;
    width: 100%;
    border-radius: 4rem;
    background-color: #F8EFEA;
    text-align: center;
    box-shadow: 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
}

.grid1 {
    margin-top: 3rem;
    display: flex;
    justify-content: space-evenly;
}

.cardService {
    box-shadow: 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
    position: relative;
    background-color: #F8EFEA;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex-direction: column;
    padding: 1.25rem 0;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    width: 21.875rem;
    height: 32rem;
    color: #E2A896;
}

.cardService:hover {
    margin-top: -1.25rem;
    margin-bottom: 1.25rem;
    transition: 1s;
}

.iconoService {
    background-color: white;
    font-size: 12.5rem;
    padding: 1.25rem;
    width: 18.75rem;
    color: #091b1a;
    border: 0.125rem solid #c08f80;
}

.serviceList {
    font-size: 1rem;
    width: 18.75rem;
    color: #E2A896;
    text-align: justify;
    position: absolute;
    top: 6rem;
    bottom: 0;
}

.location {
    display: flex;
    justify-content: space-around;
    background-color: #F8EFEA;
    padding: 0 0 0 0;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

.contactInfo {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.map {
    box-shadow: 0 0 3.125rem 0.0625rem rgba(201, 147, 131, 0.6);
}

.iconInfo {
    color: #E2A896;
    font-size: 1.25rem;
    margin-right: 0.625rem;
}

.iconInfo1 {
    color: #E2A896;
    font-size: 1.25rem;
    margin-right: 0.625rem;
}

.info {
    margin-top: 0.625rem;
    display: flex;
    align-items: center;
}

.linkInfo {
    margin-left: 0.625rem;
}

.titleInfo {
    margin-top: 1.25rem;
    border-bottom: 0.1875rem solid #E2A896;
}

.titleInfo1 {
    width: 100%;
}

.logoBian {
    width: 6.25rem;
}

.horarios {
    margin-right: 0.3125rem;
}

@media screen and (max-width:600px) {
    .serviceList {
        width: 16.75rem;
    }
    
    .logoBian {
        width: 5rem;
    }

    .titleInfo1 {
        padding: 0 0.1rem;
        margin: 0;
        font-size: 1.5rem;
    }

    .abtUsContainer {
        animation: showAbt 1s;
    }

    @keyframes showAbt {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .titleServices {
        height: 3rem;
        width: 100%;
    }

    .titleService {
        width: 100%;
    }

    .employee {
        margin: 0;
    }

    .us {
        flex-direction: column;
    }

    .empleado1 {
        flex-direction: column;
    }

    .empleado2 {
        flex-direction: column;
    }

    .txtAbtUs {
        margin: 0;
        padding: 0 2rem;
        font-size: 1rem;
    }

    .empleados {
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .employeePhoto {
        margin: 2rem 0 0 0;
        padding: 0;
        width: 300px;
    }

    .empleado1 {
        margin: 2rem 0 0 0;
    }

    .empleado2 {
        margin: 2rem 0 0 0;
    }

    .grid1 {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .location {
        flex-direction: column;
        align-items: center;
    }

    .map {
        margin-top: 4rem;
        width: 100%;
    }

    .contactInfo {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }

    .iconInfo {
        font-size: 1.5625rem;
        margin-right: 0.3125rem;
    }

    .iconInfo1 {
        font-size: 2.8125rem;
        margin-right: 0.125rem;
    }

    .cardService {
        width: 18.875rem;
        top: 2rem;
        height: 33rem;
        margin-bottom: 2rem;
    }

    .cardService:hover {
        margin-top: 0;
        top: 2rem;
        margin-bottom: 2rem;
        transition: 1s;
    }

    .servicesContainer {
        background-repeat: repeat;
    }

    .services {
        align-items: center;
        padding: 2rem 1rem 1rem 1rem;
        margin-top: -1rem;
    }
}

@media screen and (min-width:768px) and (max-width:992px) {
    .logoBian {
        width: 5rem;
    }

    .titleInfo1 {
        padding: 0 0.1rem;
        margin: 0;
        font-size: 1.5rem;
    }

    .abtUsContainer {
        animation: showAbt 1s;
    }

    @keyframes showAbt {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .titleServices {
        height: 3rem;
        width: 80%;
    }

    .titleService {
        width: 100%;
    }

    .employee {
        margin: 0;
    }

    .us {
        flex-direction: column;
    }

    .empleado1 {
        flex-direction: column;
    }

    .empleado2 {
        flex-direction: column;
    }

    .txtAbtUs {
        margin: 0;
        padding: 0 2rem;
        font-size: 1rem;
    }

    .empleados {
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .employeePhoto {
        margin: 2rem 0 0 0;
        padding: 0;
        width: 350px;
    }

    .empleado1 {
        margin: 2rem 0 0 0;
    }

    .empleado2 {
        margin: 2rem 0 0 0;
    }

    .grid1 {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .location {
        flex-direction: column;
        align-items: center;
    }

    .map {
        margin-top: 4rem;
        width: 100%;
    }

    .contactInfo {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }

    .iconInfo {
        font-size: 1.5625rem;
        margin-right: 0.3125rem;
    }

    .iconInfo1 {
        font-size: 2.8125rem;
        margin-right: 0.125rem;
    }

    .cardService {
        width: 21.875rem;
        top: 2rem;
        height: 33rem;
        margin-bottom: 2rem;
    }

    .cardService:hover {
        margin-top: 0;
        top: 2rem;
        margin-bottom: 2rem;
        transition: 1s;
    }

    .servicesContainer {
        background-repeat: repeat;
    }

    .services {
        align-items: center;
        padding: 2rem 1rem 1rem 1rem;
        margin-top: -1rem;
    }
}

@media screen and (min-width:993px) and (max-width:1365px) {
    .servicesContainer {
        background-size: 220%;
    }
    .cardService{
        width: 17rem;
    }
    .serviceList {
        width: 15rem;
    }
    /* .titleService {
        font-size: 1.5rem;
        text-align: center;
    }
    .subtitleService {
        font-size: 1.5rem;
        text-align: center;
    } */
}