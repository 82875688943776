.confirmation-page {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  margin: auto;
  padding-top: 5rem;
  box-sizing: border-box;
  background-image: url('../../Assets/background.webp');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-content {
  width: 100%;
  max-width: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
  border-radius: 10px;
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.message-text {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
  word-break: break-word;
}

.top-message {
  background: #597D76;
  border: 1px solid #5dc9c4;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 60px;
  text-align: left;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FFFFFF;
  padding: 5px;
  line-height: 1;
}

.ok-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 5px;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.ok-button:hover {
  background-color: #003333;
  color: #ffffff;
}

.confirmation-details {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
}

.confirmation-icon {
  font-size: 10rem;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4CAF50;
}

.calendar-embed {
  width: 100%;
  height: 1300px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1200px) {
  .confirmation-content {
    max-width: 95%;
  }
}

@media (max-width: 992px) {
  .confirmation-page {
    padding: 15px;
    padding-top: 5rem;
  }
  
  .confirmation-icon {
    font-size: 8rem;
  }

  .confirmation-content {
    padding: 15px;
  }

  .top-message {
    padding: 30px 30px 55px 30px;
  }

  .calendar-embed {
    height: 1300px;
  }
}

@media (max-width: 768px) {
  .confirmation-page {
    padding: 10px;
    padding-top: 5rem;
  }

  .confirmation-content {
    padding: 12px;
  }

  .top-message, .confirmation-details {
    padding: 10px;
  }

  .top-message {
    padding: 30px 30px 50px 30px;
  }

  .calendar-embed {
    height: 1300px;
  }

  .confirmation-icon {
    font-size: 6rem;
  }

  .close-button {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }

  .ok-button {
    bottom: 5px;
    right: 5px;
    padding: 6px 15px;
  }
}

@media (max-width: 576px) {
  .confirmation-page {
    padding: 8px;
    padding-top: 5rem;
  }

  .confirmation-content {
    padding: 10px;
  }

  .top-message, .confirmation-details {
    padding: 8px;
  }

  .top-message {
    font-size: 0.9rem;
    padding: 20px 20px 45px 20px;
  }

  .calendar-embed {
    height: 1300px;
  }

  .confirmation-icon {
    font-size: 5rem;
  }

  .ok-button {
    padding: 4px 12px;
    font-size: 13px;
  }

  .close-button {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .confirmation-page {
    padding: 5px;
    padding-top: 5rem;
  }

  .confirmation-content {
    padding: 8px;
  }

  .top-message {
    font-size: 0.8rem;
    padding: 20px 20px 40px 20px;
  }

  .confirmation-details {
    padding: 8px;
  }

  .calendar-embed {
    height: 1300px;
  }

  .confirmation-icon {
    font-size: 4rem;
  }

  .ok-button {
    padding: 3px 10px;
    font-size: 12px;
  }

  .close-button {
    font-size: 16px;
  }
}