.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #96e2de;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .floating-button:hover {
    background-color: #5dc9c4;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .floating-button {
      font-size: 14px;
      padding: 12px 20px;
    }
  }