.separatorDiv{
    margin-top: -0.3125rem;
    padding-bottom: 5rem;
    padding-top: 5rem;
    background: rgb(226,168,150);
    background: linear-gradient(180deg, #E2A896 30%, rgba(248,239,234,1) 100%);
    animation: showSeparator 1s;
}
@keyframes showSeparator {
    0%{
        opacity: 0;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.iconSeparators{
    width: 8rem;
}
.sr-only{
    display: none;
}
.txt1{
    font-size: 3.125rem !important;
}
.txt2{
    font-size: 1.25rem;
}
.apellido{
    color: #E2A896;
}
.imagen-test{
    width: 100%;
}
.showUpFx{
    opacity: 1;
    transition: all 2s;
}
.showUpFx{
    animation: showUp 4s;
}

@keyframes showUp {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.divContactoBtns{
    margin-top: 4rem;
    background-color: #F9EEEA;
    height: 2rem;
}
.btnsContact{
    background-color: white;
    height: 5rem;
    display: flex;
    border-bottom: 0.3125rem solid #E2A896;
    align-items: center;
    justify-content: space-evenly;
}
.contactMail{
    background-color: red;
    border: none;
    border-radius: 6.25rem;
    color: white;
    font-size: 1.25rem;
    padding: 0.3125rem 1.25rem;
    text-decoration: none;
    border: 0.125rem solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
    box-shadow: 0 0 1.875rem 0.0625rem rgba(0, 0, 0, 0.5);
}
.contactWhatsapp{
    background-color: #00b141;
    border: none;
    border-radius: 6.25rem;
    color: white;
    border: 0.125rem solid rgba(0, 0, 0, 0.3);
    font-size: 1.5625rem;
    padding: 0.3125rem 1.25rem;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 1.875rem 0.0625rem rgba(0, 0, 0, 0.5);
}
.contactTel{
    background-color: blue;
    border: none;
    border-radius: 6.25rem;
    color: white;
    border: 0.125rem solid rgba(0, 0, 0, 0.3);
    font-size: 1.25rem;
    padding: 0.3125rem 1.25rem;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 0 1.875rem 0.0625rem rgba(0, 0, 0, 0.5);
}
.iconContact{
    font-size: 1.875rem;
}
.iconContactWha{
    font-size: 2.375rem;
}
.contactMail:hover{
    padding: 0.4375rem 1.375rem;
    background-color: rgb(189, 0, 0);
    transition: 0.5s; 
    color: white; 
}
.contactWhatsapp:hover{
    padding: 0.4375rem 1.375rem;
    background-color: #009436;
    transition: 0.5s;
    color: white;   
}
.contactTel:hover{
    padding: 0.4375rem 1.375rem;
    background-color: rgb(0, 0, 190);
    transition: 0.5s;
    color: white;   
}
.imagesCarousel {

}
@media screen and (max-width:48rem) {
    .separatorDiv{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        animation: showSeparator 1s;
    }
    @keyframes showSeparator {
        0%{
            opacity: 0;
        }
        80%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .iconSeparators{
        width: 4rem;
    }
    .contactMail{
        font-size: 0.625rem;
        margin: 0;
        margin-right: 1.25rem;
        padding: 0.3125rem;
    }
    .contactTel{
        font-size: 0.625rem;
        margin: 0;
        padding: 0.3125rem;
        margin-left: 1.25rem;
    }
    .contactWhatsapp{
        font-size: 0.9375rem;
        margin: 0;
        padding: 0.625rem;
    }
    .contactWhatsapp:hover{
        padding: 0.8125rem;
    }
    .contactMail:hover{
        padding: 0.5rem;
    }
    .contactTel:hover{
        padding: 0.5rem;
    }
    .iconContact{
        font-size: 1.25rem;
    }
    .iconContactWha{
        font-size: 1.5625rem;
    }
    .btnsContact{
        display: block;
        padding-top: 1.5625rem;
    }
    .titleBtns{
        margin-top: 4rem;
        font-size: 1.25rem;
        display: block;
    }
   .txt1{
       font-size: 0.8375rem !important;
   } 
   .txt2{
       font-size: 0.5625rem !important;
       width: 100%;
   }
   .apellido{
       font-size: 0.9375rem !important;
   }
   .carousel{
       margin-bottom: 0;
       margin-top: 4rem;
       width: 100%;
   }
}

