.calendar-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 4rem;
    box-sizing: border-box;
}

.responsive-iframe {
    width: 100%;
    height: 90vh;
    border: none;
}

@media (max-width: 768px) {
    .responsive-iframe {
        height: 80vh;
    }
}