@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
.sidebar{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 4rem;
}
.navlinks{
    text-decoration: none;
    color: #555555;
    display: flex;
    align-items: center;
}
.navlinks:hover{
    color: #555555;
}
.mediaLinks{
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
}
.mediaLinks:hover{
    color: black;
}
.leftSideNav{
    display: flex;
    padding-left: 2rem;
}
.list{
    list-style-type: none;
    background-color: #E2A896;
    width: 100vw;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}
.items{
    font-size: 1rem;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 3rem;
    font-weight: bolder;
    padding: 1.25rem;
    border-bottom: solid 0.3125rem rgba(248,239,234,1);
    margin: 0.625rem;
}
.schedule_consultation{
    background-color: #96e2de;
    border: none;
    border-radius: 50px;
}
.shop {
    border: none;
    border-radius: 50px;
    background-color: #6D8A74;
}
.items:hover{
    transition: 0.5s;
    background-color: rgba(201, 147, 131, 0.6);
}
.schedule_consultation:hover {
    transition: 0.5s;
    background-color: #5dc9c4;
}
.shop:hover {
    transition: 0.5s;
    background-color: #607a66;
}
.btnResponsive{
    display: none;
    position: absolute;
    left: 0.625rem;
    top: 0.625rem;
    padding: 0.3125rem;
    color: #ffffff;
    font-size: 1.875rem;
    background: transparent;
    border:none;
}
.socialMedias{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
}
.iconoIg{
    border: none;
    font-size: 1.5625rem;
    border-left: 0.3125rem solid rgba(248,239,234,1);
    border-right: 0.3125rem solid rgba(248,239,234,1);
    padding: 0 0.9375rem;
    display: flex;
    text-align: right;
    align-items: center;
    color: #ffffff;
    height: 3rem;
    background-color: transparent;
}
.iconoLin{
    color: #ffffff;
    padding: 0 0.9375rem;
    display: flex;
    align-items: center;
    border: none;
    height: 3rem;
    border-left: 0.3125rem solid rgba(248,239,234,1);
    border-right: 0.3125rem solid rgba(248,239,234,1);
    font-size: 1.5625rem;
    background-color: transparent;
}
.iconoLin:hover{
    background-color: rgba(201, 147, 131, 0.6);
    transition: 1s;
}
.iconoIg:hover{
    background-color: rgba(201, 147, 131, 0.6);
    transition: 1s;
}
@media screen and (max-width:48rem) {
    .schedule_consultation{
        
    }
    .leftSideNav{
        flex-direction: column;
        padding: 0;
    }
    .list{
        flex-direction: column;
        height: auto;
        padding: 0;
        border-bottom: 0.125rem solid #b9b9b9;
        margin: 0;
        width: 100%;
    }
    .navlinks:nth-child(1){
        border-top: solid 0.3125rem rgba(248,239,234,1);
        width: 100vw;
        margin-top: 4rem;
        padding-top: 1.25rem;
    }
    .items{
        width: 100%;
        text-align: center;
        margin-right: 0rem;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 1.25rem 0;
    }
    .navlinks{
        width: 100%;
        margin-top: 1rem;
    }
    .btnResponsive{
        display: block;
        display: flex;
        align-items: center;
        margin-top: -0.3125rem;
    }
    .contactWhatsappResponsive{
        display: block;
    }
    .iconoIg{
        left: 0;
    }
    .iconoFb{
        left: 0;
    }
    .iconoLin{
        left: 0;
    }
    .socialMedias{
        padding: 0;
        margin: 1.625rem 0;
    }
    .sidebar{
        border-bottom: 0.125rem solid #ffffff;
        background-color: #E2A896;
        width: 100vw;
    }
}
@media screen and (min-width:48rem) and (max-width:64rem) {
    .iconoIg{
        left: 0vw;
    }
    .iconoFb{
        left: 0vw;
    }
    .iconoLin{
        left: 0vw;
    }
}