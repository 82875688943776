@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@500&display=swap');
body {
  margin: 0;
  font-family: 'Frank Ruhl Libre' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
	width: 0.625rem;
	background-color:  #E2A896;
}

::-webkit-scrollbar-track {
	background-color: #E2A896;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(201, 147, 131, 0.6);
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgb(201, 147, 131);
}

::-webkit-scrollbar-thumb:active {
	background-color: rgb(201, 147, 131);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

