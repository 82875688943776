
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
}
._cUP1np9gMvFQrcPftuf .OGcBAyJGBej5Gnyi9hGA .xahN8AEzyAvQtVj17TPv ._Y8HCTxgNkwxXcG_DCXx{
 margin: 0 !important;
}

.contactContainer {
    background-image: url('../../Assets/background.webp');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 5rem 0;
}
.contactForm{
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #F8EFEA;
    border-left: 0.0625rem solid white;
    border-right: 0.0625rem solid white;
    border-bottom: 0.0625rem solid white;
    border-bottom-left-radius: 1.875rem;
    border-bottom-right-radius: 1.875rem;
    padding: 1.25rem 2.5rem;
    width: 70%;
    margin-top: -5px;
}
.titleContact{
    background-color: #F8EFEA;
    color: #E2A896;
    width: 70%;
    border-top-left-radius: 1.875rem;
    border-top-right-radius: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    margin-bottom: 0rem;
    padding-top: 1rem;
}
.titleContact1{
    color:white;
}
.titleLabel{
    font-size: 1.25rem;
    color: #E2A896;
}
.inputStyle{
    width: 100%;
    margin: 0rem 0;
    border: 0.0625rem solid #9c7467;
    border-radius: 0.125rem;
    height: 2.5rem;
    color: rgb(87, 76, 76);
    padding: 0 0.625rem;
    background-color: #F8EFEA;
}
.inputStyle:invalid{
    border: 0.125rem solid rgb(255, 67, 67);
}
.txtAreaStyle{
    margin: 0.625rem 0;
    color: rgb(87, 76, 76);
    width: 100%;
    padding: 0.3125rem 0.625rem;
    border: 0.0625rem solid #9c7467;
    border-radius: 0.3125rem;
    background-color: #F8EFEA;
}
.btnSend{
    border: 0.125rem solid #E2A896;
    border-radius: 0.3125rem;
    font-size: 1.25rem;
    padding: 0.3125rem 1.25rem;
    color: #ffffff;
    background-color: #E2A896;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSend:hover{
    background-color: rgba(201, 147, 131, 1);
    color: white;
    border: 0.125rem solid #c08f80;
}
@media screen and (max-width:768px) {
    ._cUP1np9gMvFQrcPftuf .OGcBAyJGBej5Gnyi9hGA .xahN8AEzyAvQtVj17TPv ._Y8HCTxgNkwxXcG_DCXx{
        margin: 0 !important;
    }
    .contactContainer{
        margin: 0;
        padding: 0 0 3.125rem 0;
        width: 100%;
    }
    .contactForm{
        height: 100%;
        padding-top: 2rem;
        width: 85%;
    }
    .titleContact{
        margin: 0rem 0 -5px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 8rem;
        font-size: 1.5rem;
        width: 85%;
        padding: 0;
        padding-top: 20px;
    }
    .titleLabel{
        font-size: 1rem;
    }
    .formContainer {
        margin-top: 5rem;
    }
 }

 @media only screen and (max-width: 991px) {
    ._cUP1np9gMvFQrcPftuf .OGcBAyJGBej5Gnyi9hGA .xahN8AEzyAvQtVj17TPv ._Y8HCTxgNkwxXcG_DCXx{
        margin: 0 !important;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    ._cUP1np9gMvFQrcPftuf .OGcBAyJGBej5Gnyi9hGA .xahN8AEzyAvQtVj17TPv ._Y8HCTxgNkwxXcG_DCXx{
        margin: 0 !important;
       }
  }

  